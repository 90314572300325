<template>
      <div>
        <div class="w-screen section-container laybare-banner relative space-top" id="why-laybare">
            <div class="laybare-intro px-2 pb-5 mobileL:px-4 z-10 max-w-sm w-3/4 mobileL:max-w-md 2xl:max-w-3xl
              text-left justify-end mobileL:text-center h-full flex flex-col mobileL:items-center mobileL:justify-center ml-4 xl:ml-20 2xl:ml-20">
                <p class="text-base xl:text-xl 2xl:text-2xl text-white-1">We believe that having smooth, hair-free skin is the first step
                toward your best self. With Lay Bare, we've elevated the experience -
                making it affordable, accessible and more comfortable.</p>
            </div>
        </div>
    
        <div class="w-screen when-you-see-hair-section flex justify-center items-center ">
            <div class="content-wrapper max-w-5xl mx-10">
                <img class="w-full" src="../../assets/images/home-page/when you see hair.png" alt="when you see hair">
            </div>
        </div>
    
        <div class="our-historys-section px-3 py-12 xl:px-6 xl:py-16 2xl:px-8 2xl:py-20">
            <div class="custom-w-8xl mx-4 mobileL:mx-auto mb-10 ">
                <div class="grid mobileL:grid-cols-2 items-center">
                    <div class="our-history-image-container">
                        <img class="mx-auto" src="../../assets/images/home-page/our-history.png" alt="Our History">
                    </div>
                    <div class="customer-experience-content-container flex flex-col  justify-center text-brown-4 ">
                        <h3 class="font-bold text-center text-3xl mobileL:text-left mobileL:font-extrabold mobileL:text-5xl 2xl:text-6xl">Our History</h3>
                        <p class=" text-base text-center mobileL:text-left mobileL:text-xl xl:text-2xl 2xl:text-3xl">Lay Bare began as a family-owned salon in 2006 when three
                        siblings founders Paolo, Fiona and Monique couldn't find a
                        salon dedicated to the art of waxing. So, they set out to
                        craft the perfect experience and introduced a new world of
                        waxing possibilities for those who dared to bare it all.</p>
                    </div>
                </div>
            </div>
    
            <div class="custom-w-8xl mx-4 mobileL:mx-auto mb-24  ">
                <div class="grid mobileL:grid-cols-2 items-center">
                    <div class="our-history-content-container flex flex-col justify-center text-brown-4 text-right order-2 mobileL:order-1">
                        <h3 class="text-center text-3xl font-bold mobileL:text-right mobileL:font-extrabold mobileL:text-4xl xl:text-5xl 2xl:text-6xl">The Customer Experience</h3>
                        <div class="text-center text-base mobileL:text-right mobileL:text-xl xl:text-2xl 2xl:text-3xl">
                            <p class="mb-8">From day one, we've been putting our
                            values into action to delight our guests with an
                            exceptional waxing experience. From the private, comfy
                            service room to the after-care we provide, our goal is
                            to elevate what we offer by making it accessible,
                            affordable and comfortable for our Loyalistas.</p>
                            <p>Each
                            service is designed and executed with the highest
                            standards and the goal of creating an extraordinary
                            experience, resulting in happy customers eager to
                            partake another ‘Hair Free Morning’ encounter.</p>
                        </div>
                    </div>
                    <div class="customer-experience-image-container order-1 mobileL:order-2">
                        <img class="mx-auto" src="../../assets/images/home-page/customer-experience.png" alt="Customer Experience">
                    </div>
                </div>
            </div>
    
    
            <div class="flex flex-col justify-center md:flex-row md:justify-around px-4 md:mx-auto w-full">
                <div class="flex justify-center mx-8 mobileL:mx-auto mobileL:justify-end items-center order-3 mobileL:order-1">
                    <img
                    class=" w-56 mobileL:w-72 xl:w-96 2xl:w-100 "
                    src="../../assets/images/home-page/honey.png"
                    alt="Honey">
                </div>
                <div class="max-w-md xl:max-w-xl 2xl:max-w-screen-md  mobileL:mx-auto text-center text-brown-4 order-2 ">
                    <h3 class="font-bold text-3xl mobileL:font-extrabold mobileL:text-4xl xl:text-6xl my-2">Honey, Sugar, and Calamansi</h3>
                    <p class=" text-base mobileL:text-xl xl:text-2xl 2xl:text-3xl mobileL:leading-loose">All the good stuff in our signature cold wax</p>
                    <p class=" text-base mobileL:text-xl xl:text-2xl 2xl:text-3xl mobileL:leading-loose">Our recipe goes way back. We've developed tons of mixture but this particular one is just the perfect balance to suit every skin type.</p>
                </div>
                <div class="flex justify-center mx-8 mobileL:mx-auto mobileL:justify-start items-center order-1 mobileL:order-3">
                    <img
                    class="w-56 mobileL:w-72 xl:w-96 2xl:w-100 right-8 xl:right-24 2xl:right-48 2xl:-top-12"
                    src="../../assets/images/home-page/sugar-and-calamansi.png"
                    alt="Sugar and Calamansi">
                </div>
            </div>
        </div>
    
        <div class="why-wax-section px-10 py-8 bg-brown-5 text-center ">
            <h3 class="text-brown-4 text-4xl mobileL:text-5xl xl:text-6xl 2xl:text-7xl font-century font-bold mobileL:font-extrabold">Why Wax?</h3>
            <div class="flex flex-col mobileL:flex-row mobileL:justify-between items-center max-w-5xl 2xl:max-w-7xl mx-auto xl:gap-4 2xl:gap-10">
                <div class="first-item">
                    <img class="md:w-8/12 mx-auto" src="../../assets/images/home-page/1.png" alt="Why wax">
                    <p class="text-2xl mobileL:text-xl xl:text-2xl 2xl:text-3xl font-bold text-brown-4">Hair will grow back thinner and lasts a lot longer</p>
                </div>
    
                <div class="first-item">
                    <img class="md:w-8/12 mx-auto" src="../../assets/images/home-page/2.png" alt="Why wax">
                    <p class="text-2xl mobileL:text-xl xl:text-2xl 2xl:text-3xl font-bold text-brown-4">It’s better for your skin</p>
                </div>
    
                <div class="first-item">
                    <img class="md:w-8/12 mx-auto" src="../../assets/images/home-page/3.png" alt="Why wax">
                    <p class="text-2xl mobileL:text-xl xl:text-2xl 2xl:text-3xl font-bold text-brown-4">Exfoliates skin and minimizes ingrown hairs</p>
                </div>
            </div>
        </div>
    
        <div class="testimonials-section w-screen p-10 py-16 bg-gradient-to-b from-green-9 to-green-11 text-center text-white-1 " id="testimonials">
            <h3 class="flex justify-center font-tahu  text-4xl mobileL:text-5xl xl:text-6xl 2xl:text-7xl mb-6 mobileL:mb-20 whitespace-nowrap">What our clients say about us?</h3>
                <div class="grid mobileL:grid-cols-3 justify-center items-center mb-5 mobileL:mb-20 xl:max-w-5xl 2xl:max-w-full mx-auto">
                    <div class="grid-item pb-4 flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full">
                    <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto" src="../../assets/images/home-page/quotationmark-white.png" alt="Quotation mark">
                    <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">Super galing and ang babaet po ng staff. Super worth it yung services!</p>
                    <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">H.</h4>
                </div>
    
                <div class="grid-item pb-4 flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" v-show="shouldShowElement ||isVisible ">
                    <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets/images/home-page/quotationmark-white.png" alt="Quotation mark">
                    <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">The Threading is excellent! Service is fantastic. They are so nice and they make my eyebrows look amazing!</p>
                    <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">C.</h4>
                </div>
    
                <div class="flex flex-col justify-between gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" v-show="shouldShowElement || isVisible">
                    <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets/images/home-page/quotationmark-white.png" alt="Quotation mark">
                    <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">such a quick and professional service, esp. sm manila branch, well accomodating, luv the staffs ❤️</p>
                    <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">M.</h4>
                    </div>
            </div>
            <div class="grid mobileL:grid-cols-3 justify-between items-center xl:max-w-5xl 2xl:max-w-full mx-auto" v-show="isVisible">
                <div class="grid-item-with-top py-4 mobileL:py-0 mobileL:border-none flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full">
                    <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto" src="../../assets/images/home-page/quotationmark-white.png" alt="Quotation mark">
                    <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">their service was so good and the staff are kind. I'll come back again next time!</p>
                    <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold mb-4">N.</h4>
                </div>
            </div>
            <button
            class="toggle-btn bg-white-1 mt-5 text-green-9 font-extrabold px-4 xl:px-5 2xl:px-7 py-2 xl:py-3 2xl:py-4 rounded-full text-base xl:text-xl 2xl:text-2xl"
            @click="showMoreFeedback()" ref="toggleButton">
            Load More!
            </button>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        windowWidth: window.innerWidth,
      }
    },

    computed: {
      shouldShowElement() {
      return this.windowWidth > 480;
      },
      
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      showMoreFeedback() {
        this.isVisible = !this.isVisible;
        this.$refs.toggleButton.innerText = this.isVisible ? 'Load Less!' : 'Load More!';
      }
    }
  }
  </script>
  
  <style scoped>
  
  .laybare-banner-color{
      background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);;
  
  }
  .section-container {
      height: 600px;
  }
  
  .space-top {
    margin-top: 90px;
  }
  
  .laybare-banner {
    background: url('../../assets/images/home-page/lay-bare-banner.jpg') no-repeat;
    background-size: 100% 100%;
  }
  
  .when-you-see-hair-section {
      background: url('../../assets/images/home-page/when-you-see-hair-bg.jpg') no-repeat;
      background-size: 100% 100%;
      
  }
  
  .when-you-see-hair-content {
      font-size: 4.25rem;
      text-shadow: 6px 6px #6CB93C;
  }
  
  .our-historys-section {
      background: url('../../assets/images/home-page/body-background.jpg') no-repeat;
      background-size: cover;
  }
  
  .custom-w-8xl {
      max-width: 1700px;
  }
  
  .our-history-image-container img, .customer-experience-image-container img {
      width: 40rem;
  }
  
  .why-wax-section h3 {
      text-shadow: -4px 4px #D1AE86;
  }
  
  .grid-item:not(:nth-child(3n)) {
      border-right: 3px solid #fff;
  }
  
  .toggle-btn {
      box-shadow: 0px 4px 0px 0px #5D9733;
  }
  
  @media (max-width: 480px) {
      .space-top {
        margin-top: 60px;
      }
      
      .when-you-see-hair-section {
        height: 152px;
      }
      .laybare-banner {
        background: url('../../assets/images/home-page/lay-bare-banner-mobile.jpg') no-repeat;
        background-size: cover;
      }
  
      .section-container {
        height: 420px;
      }
      .our-history-image-container img, .customer-experience-image-container img {
        width: 225px;
      }
      .grid-item:not(:nth-child(3n)) {
        border-bottom: 3px solid #fff;
        border-right: 0px;
      }
      .grid-item-with-top:not(:nth-child(3n)) {
        border-bottom: 3px solid #fff;
        border-top: 3px solid #fff;
        border-right: 0px;
      }
  }
  
  @media (min-width: 1280px) {
      .space-top {
        margin-top: 100px;
      }
  
      .section-container {
        height: 600px;
      }

      .our-history-image-container img, .customer-experience-image-container img {
        width: 30rem;
      }
  
      .when-you-see-hair-content {
          font-size: 5.25rem;
      }
  }
  
  @media (min-width: 1536px) {
      .space-top {
        margin-top: 128px;
      }
  
      .section-container {
        height: 110vh;
      }

      .our-history-image-container img, .customer-experience-image-container img {
        width: 40rem;
      }
  
      .when-you-see-hair-content {
        font-size: 6.25rem;
      }
  }
  
  </style>